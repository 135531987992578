const { REACT_APP_API_BASE_URL } = process.env;

export const getLastNthDay = (n = 0) => {
  const today = new Date();
  const last7thDay = new Date(today);
  last7thDay.setDate(today.getDate() - n);
  return last7thDay.toISOString().slice(0, 10);
};

export const requestHeader = (apiEndPoint, method, formData = {}) => {
  const config = {
    method: method,
    url: `${REACT_APP_API_BASE_URL}${apiEndPoint}`,
    headers: {
      "Content-Type": "application/json"
    },
    data: formData
  };

  return config;
};

export const getBarChartData = (apiData) => {
  const leadsInfo = apiData.outbound_calls_per_lead;

  const leadsName = Object.keys(leadsInfo);

  const totalReceivingCalls = leadsName.map(
    (leadName) => leadsInfo[leadName].total
  );
  const totalAnsweredCalls = leadsName.map(
    (leadName) => leadsInfo[leadName].answered
  );

  return {
    labels: leadsName,
    datasets: [
      {
        label: "Total",
        data: totalReceivingCalls, // list of all total inside all leads
        backgroundColor: "#33b2ea"
      },
      {
        label: "Completed",
        data: totalAnsweredCalls, // list of all answer inside all leads
        backgroundColor: "#1c994a"
      }
    ]
  };
};

export const getPieChartData = (apiData) => {
  const piChartInfo = apiData.outbound_total_calls;
  const pieChartLabel = Object.keys(piChartInfo).map((key) => {
    return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
  });
  const pieChartValue = Object.values(piChartInfo);
  console.log(pieChartLabel);
  return {
    labels: pieChartLabel,
    datasets: [
      {
        label: "# of calls",
        data: pieChartValue,
        backgroundColor: ["#1c994a", "#33b2ea", "#ffe053", "#e61f4a", "#762ef1"]
      }
    ]
  };
};
